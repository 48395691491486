define("discourse/plugins/discourse-tickets/discourse/controllers/admin-tickets", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-tickets/discourse/lib/ticket-utilities", "discourse/lib/ajax", "@ember/controller", "@ember/array", "@ember/object/computed"], function (_exports, _decorators, _ticketUtilities, _ajax, _controller, _array, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    queryParams: ['order', 'filters'],
    filterFields: (0, _ticketUtilities.generateSelectKitContent)((0, _array.A)(['tag', 'status', 'priority', 'reason', 'assigned'])),
    order: null,
    asc: true,
    currentFilters: (0, _array.A)(),
    page: 0,
    pageStart: (0, _computed.alias)('offset'),
    previousDisabled: (0, _computed.equal)('page', 0),
    nextDisabled(page, totalPages) {
      return page === totalPages - 1;
    },
    offset(page, perPage) {
      return page * perPage;
    },
    lessThanLimit(total, perPage) {
      return total <= perPage;
    },
    pageStart(offset) {
      return offset > 0 ? offset + 1 : 0;
    },
    pageEnd(offset, perPage, total) {
      let end = offset + perPage;
      return end > total ? total : end;
    },
    totalPages(perPage, total) {
      return Math.ceil(total / perPage);
    },
    pages(totalPages, page) {
      let pages = [];
      for (let i = 1; i <= totalPages; i++) {
        let classes = '';
        if (i === page + 1) {
          classes += 'btn-primary';
        }
        pages.push({
          number: i,
          classes
        });
      }
      return pages;
    },
    updateFilterValues() {
      this.set('filterValues', (0, _ticketUtilities.generateSelectKitContent)(this.get('valueMap')[this.get('filterField')]));
      this.set('filterValue', null);
    },
    refreshTickets() {
      this.set("refreshing", true);
      this.getTickets().then(() => {
        this.set("refreshing", false);
      });
    },
    getTickets(page) {
      this.set('refreshing', true);
      let data = {
        order: this.get("order"),
        ascending: this.get("asc")
      };
      if (page) {
        data['page'] = page;
      }
      const currentFilters = this.get('currentFilters');
      if (currentFilters) {
        data['filters'] = currentFilters.map(f => {
          return `${f.field}:${f.value}`;
        }).join(',');
      }
      ;
      return (0, _ajax.ajax)('/tickets', {
        data
      }).then(result => {
        this.setProperties({
          tickets: result.tickets,
          total: result.total,
          perPage: result.per_page,
          page: result.page,
          valueMap: (0, _ticketUtilities.generateValueMap)(result.tickets)
        });
      }).finally(() => this.set('refreshing', false));
    },
    actions: {
      applyFilter() {
        let field = this.get('filterField');
        this.get('filterFields').removeObject(field);
        this.get('currentFilters').pushObject({
          field,
          value: this.get('filterValue')
        });
      },
      removeFilter(filter) {
        let field = filter.field;
        this.get('filterFields').addObject(field);
        this.get('currentFilters').removeObject(this.get('currentFilters').findBy('field', field));
      },
      filterBy(field, value) {
        this.get('currentFilters').pushObject({
          field,
          value
        });
      },
      nextPage() {
        this.getTickets(this.get('page') + 1);
      },
      previousPage() {
        let page = this.get('page') - 1;
        this.getTickets(page < 0 ? 0 : page);
      },
      goToPage(page) {
        this.getTickets(page - 1);
      }
    }
  }, [["method", "nextDisabled", [(0, _decorators.default)('page', 'totalPages')]], ["method", "offset", [(0, _decorators.default)('page', 'perPage')]], ["method", "lessThanLimit", [(0, _decorators.default)('total', 'perPage')]], ["method", "pageStart", [(0, _decorators.default)('offset')]], ["method", "pageEnd", [(0, _decorators.default)('offset', 'perPage', 'total')]], ["method", "totalPages", [(0, _decorators.default)('perPage', 'total')]], ["method", "pages", [(0, _decorators.default)('totalPages', 'page')]], ["method", "updateFilterValues", [(0, _decorators.observes)('filterField')]], ["method", "refreshTickets", [(0, _decorators.observes)("order", "asc", "currentFilters.[]")]]]));
});